.glass{
    /* background: rgba(43,67,137,0.15);
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px); */
    border: 1px solid white;
    padding: 1.5vh;
    width: max-content;
    margin-bottom: 10px;
    border-radius: 10px;
    margin-right: 1vw;
    /* background: transparent !important; */
    font-size: 1.1rem;
  
}
.mainbox{
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.528);
    margin-top:10vh;
    border-radius: 5px;
    padding: 10vh;
    width: max-content;
    position: relative;
    height: max-content;
}

.floral-design {
    position: absolute;
    top: 0;
    right: 0; 

  }

  #please{
    background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
    color: whitesmoke !important;
    padding: 2vh;
    border: none;
}

  .imgg{
    height: 100%;
    width: 10%;
    opacity: 0.18;
  }
  .svg{
    width: auto;
    height: 100%;
  }
  
  .floral-design img {
    width: 100%;
    height: fit-content;
    object-fit: cover;
   
  }
.form{
margin-left: 20vw;
}
.brandname{
    margin-left: 30%;
    margin-bottom: 6vh;
}
.questions{
   
    width: 85%;
    display: grid;
    grid-template-columns: 30vw 30vw;


}


.answers{
    border: none;
    opacity: 0.4;
    padding: 0 10px; /* Add padding to fix text alignment */
    width: 25vw;
    background-color: transparent;
    border-bottom: 1px black solid;
  }
  
  ul {
    list-style: none;
  }
  
  input:focus{
    outline: none;
 
  }
